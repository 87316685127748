<template>
  <div class="content">
    <el-row class="bgW">
      <el-col :span="22">
        <!--检索条件-->
        <el-form class="search" label-suffix="：" :inline="true" size="mini" :model="search">
<!--          <el-form-item label="姓名">
            <el-input
              v-model="search.customer_name"
              maxlength="30"
              class="w84"
              placeholder="请输入"
              clearable
            />
          </el-form-item> -->
          <el-form-item label="电话">
            <el-input
              v-model="search.telephone"
              maxlength="30"
              class="w100"
              placeholder="请输入"
              clearable
            />
          </el-form-item>
          <el-form-item label="性别">
            <el-select v-model="search.sex" clearable placeholder class="w80">
              <el-option
                v-for="item in options.sex"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="年龄">
            <el-input
              v-model="search.agemin"
              maxlength="30"
              class="w70"
              clearable
              placeholder="最小"
            />
            <span></span>
            <el-input
              v-model="search.agemax"
              maxlength="30"
              class="w70"
              clearable
              placeholder="最大"
            />
          </el-form-item>
          <el-form-item label="是否进店">
            <el-select v-model="search.effective_entry" clearable class="w80">
              <el-option
                v-for="item in options.effective_entry"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="新老客户">
            <el-select v-model="search.is_new" clearable class="w80">
              <el-option
                v-for="item in options.is_new"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="会员等级">
            <el-select v-model="search.membership_leve" clearable class="w100">
              <el-option
                v-for="item in options.membership_leve"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>

          <el-form-item label="门店类型" prop="shop_type">
            <el-select
              v-model="search.shop_type"
              class="w100"
              clearable
              placeholder="门店类型"
            >
              <el-option
                v-for="item in options.type"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="门店/门诊" v-if="!userInfo.shop_id">
            <el-select
              v-model="search.shop_id"
              clearable
              remote
              filterable
              :remote-method="remoteMethod"
              placeholder="请输入"
              class="w120"
            >
              <el-option
                v-for="item in options.shop_id"
                :key="item.shopId"
                :label="item.shopName"
                :value="item.shopId"
              />
            </el-select>
          </el-form-item>

          <el-form-item label="客户分类">
            <el-select v-model="search.member_classification" clearable class="w100">
              <el-option
                v-for="item in options.member_classification"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="客户保有状态">
            <el-select v-model="search.customer_type" clearable class="w100">
              <el-option
                v-for="item in options.customer_type"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="疤痕类型">
            <el-select v-model="search.scar_id" clearable class="w100">
              <el-option
                v-for="item in options.scar_id"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="消费金额">
            <el-input
              v-model="search.consumption_min"
              maxlength="30"
              class="w100"
              clearable
              placeholder="最小金额"
            />
            <span></span>
            <el-input
              v-model="search.consumption_max"
              maxlength="30"
              class="w100"
              clearable
              placeholder="最大金额"
            />
          </el-form-item>
          <el-form-item label="积分">
            <el-input
              v-model="search.point_min"
              maxlength="30"
              class="w70"
              clearable
              placeholder="最小"
            />
            <span></span>
            <el-input
              v-model="search.point_max"
              maxlength="30"
              class="w70"
              clearable
              placeholder="最大"
            />
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="2" style="text-align: right;">
        <el-button type="primary" icon="el-icon-search" size="mini" @click="getList('search')">搜索</el-button>
      </el-col>
    </el-row>
    <div class="accountCon">
      <div class="btnBox">
        <el-button type="primary" round class="mb10" size="mini" @click="tapBtn('群发','')">发送给所有人</el-button>
        <el-button type="primary" round class="mb10" size="mini" @click="tapBtn('选中','')">发送给所有人</el-button>
      </div>
      <!--列表-->
      <el-row>
        <el-col :span="24">
          <el-table
            :data="list"
            size="small"
            border
            v-loading="loading"
            :cell-style="$style.cellStyle"
            :header-cell-style="$style.rowClass"
            @selection-change="handleSelectionChange"
          >
            <ElTableColumn v-if="!userInfo.shop_id" type="selection" width="50" />
            <ElTableColumn label="门店" width="150" prop="shop_name"  fixed="left"/>
            <ElTableColumn label="姓名" prop="customer_name" width="100"  fixed="left"/>
            <ElTableColumn label="联系电话" prop="telephone" width="100" fixed="left"/>
            <ElTableColumn label="性别" width="50" prop="sex" />
            <ElTableColumn label="年龄" width="50" prop="age" />
            <ElTableColumn label="操作" fixed="right">
              <template slot-scope="{ row }">
                <span class="theme" @click="tapBtn('短信',row)">短信发送</span>
                <el-divider direction="vertical"></el-divider>
                <span class="theme" @click="tapBtn('公众号',row)">公众号发送</span>
              </template>
            </ElTableColumn>
          </el-table>
          <pagination
            class="page tc mt10"
            :total="page.total"
            :page.sync="page.page"
            :limit.sync="page.limit"
            @pagination="getList"
          />
        </el-col>
        <el-dialog
          title="选择模板"
          :visible.sync="dialogVisible"
          width="60%"
          >
            <el-table
            v-if="tpl_type=='shortMessage'"
              :data="tpl_list"
              size="small"
              border
              :cell-style="$style.cellStyle"
              :header-cell-style="$style.rowClass"
              @selection-change="handleSelectionChange"
            >
            <ElTableColumn label="模板名称" prop="name" width="100"/>
            <ElTableColumn label="内容"  prop="content" />
              <ElTableColumn label="审核状态" width="100">
                <template slot-scope="{ row }">
                  <div v-if="row.status == 0">审核中</div>
                  <div v-if="row.status == 1">通过</div>
                  <div v-if="row.status == 2">未通过</div>
                </template>
              </ElTableColumn>
             <ElTableColumn label="操作"  fixed="right" width="100">
                <template
                  slot-scope="{ row }"
                >
                  <span class="theme" @click="tapBtn('发送',row)"> 选中</span>
                </template>
              </ElTableColumn>
            </el-table>
            <el-table
            v-if="tpl_type=='gzh'"
              :data="gzh_tpl_list"
              size="small"
              border
              :cell-style="$style.cellStyle"
              :header-cell-style="$style.rowClass"
              @selection-change="handleSelectionChange"
            >
            <ElTableColumn label="模板名称" prop="title" width="100"/>
            <ElTableColumn label="内容"  prop="content" />
             <ElTableColumn label="操作"  fixed="right" width="100">
                <template
                  slot-scope="{ row }"
                >
                  <span class="theme" @click="tapBtn('发送',row)"> 选中</span>
                </template>
              </ElTableColumn>
            </el-table>
            <el-button @click="dialogVisible = false">取 消</el-button>
            <!-- <el-button type="primary"  @click="tapBtn('add')">确 定</el-button> -->
          </span>
        </el-dialog>
      </el-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  clientList,
  ssettingList,
  statusSetting,
  getGradeList,
  clientExport,
  getTwoLabel
} from "@/api/client";
import pagination from "@/components/Pagination";
import { getShopList,getMarketLsit } from "@/api/shop";
import { getStaffList } from "@/api/staff";
import { getshortmessagetpllist,tplCancel,addModel,shortMessageMass,shortMessageSend,shortMessageSends,weChatGzhTpl } from "@/api/permission";


class Search {
  create_time_start = "";
  create_time_end = "";
  customer_name = "";
  sex = 0;
  agemin = "";
  agemax = "";
  source_id = "";
  effective_entry = 0;
  customer_service_name = "";
  membership_leve = "";
  shop_id = "";
  nurse_id = "";
  member_classification = "";
  repair_progress = "";
  turn_status = "";
  source_name = "";
  scar_id = "";
  difficulty_level = "";
  consumption_min = "";
  consumption_max = "";
  shop_type = "";
  market_id = "";
  is_new = 0;
  customer_type = 0;
  point_min="";
  point_max="";
  tpl_type = "";
}
class Options {
  sex = [
    { id: 0, name: "全部" },
    { id: 1, name: "男" },
    { id: 2, name: "女" }
  ];
  source_id = [];
  effective_entry = [
    { id: 0, name: "全部" },
    { id: 1, name: "是" },
    { id: 2, name: "否" }
  ];
  membership_leve = [];
  member_label = [];
  shop_id = [];
  market_id = [];
  nurse_id = [];
  member_classification = [];
  repair_progress = [];
  turn_status = [
    { id: 0, name: "全部" },
    { id: 1, name: "待处理" }
  ];
  scar_id = [];
  difficulty_level = [
    { id: 1, name: "1级" },
    { id: 2, name: "2级" },
    { id: 3, name: "3级" },
    { id: 4, name: "4级" }
  ];
  type = [
    {id: "1",name: "直营"},
    {id: "2",name: "加盟"},
    {id: "3",name: "直管"}
  ];
  is_new = [
    { id: 0, name: "全部" },
    { id: 1, name: "新" },
    { id: 2, name: "旧" }
  ];
  customer_type = [
    { id: 0, name: "全部" },
    { id: 1, name: "保有顾客" },
    { id: 2, name: "流失顾客" }
  ];
}
class Page {
  total = 0;
  page = 1;
  limit = 10;
}
export default {
  name: "ClientList",
  components: {
    pagination
  },
  data() {
    return {
      search: new Search(),
      options: new Options(),
      page: new Page(),
      list: [],
      idList: "",
      loading: false,
      dialogVisible:false,
      tpl_list:[],
      gzh_tpl_list:[],
    };
  },
  filters: {
    statusFilter(val) {
      return ["启用", "禁用"][+val];
    }
  },
  computed: {
    ...mapGetters(["userInfo"])
  },
  mounted() {
    this.getOptions();
    if (typeof this.$route.query.search == "object") {
      this.search = this.$route.query.search;
      this.page = this.$route.query.page;
    }
    this.getList();
  },
  methods: {
    // 获取选项
    getOptions() {
      getGradeList().then(res => {
        this.options.membership_leve = res.list;
      });
      getTwoLabel().then(res => {
        this.options.member_label = res.data;
      });
      ssettingList({ page: 1, limit: 100000 }).then(res => {
        let that = this;
        res.list.forEach(function(item) {
          if (item.category_id == 1) {
            that.options.scar_id.push(item);
          } else if (item.category_id == 4) {
            that.options.source_id.push(item);
          } else if (item.category_id == 5) {
            that.options.repair_progress.push(item);
          } else if (item.category_id == 8) {
            that.options.member_classification.push(item);
          }
        });
      });
      getshortmessagetpllist({status:1}).then(res => {
        this.tpl_list = res.data;
      });
      weChatGzhTpl().then(res => {
        this.gzh_tpl_list = res.data.template_list;
      });
    },
    // 获取列表
    getList(option) {
      this.loading = true;
      if (option === "search") this.page = new Page();
      shortMessageMass({ ...this.search, ...this.page}).then(
        res => {
          this.list = res.data;
          this.page.total = res.total;
          this.loading = false;
        }
      );
    },
    // 搜索市场
    getMarket(e) {
      getMarketLsit({ page:1, limit:100000, marketName:e }).then(res => {
        console.log(res);
        this.options.market_id = res;
      })
    },

    // 搜索门店
    remoteMethod(e) {
      getShopList({ page: 1, limit: 100000, shopName: e }).then(res => {
        this.options.shop_id = res.list;
      });
    },

    // 搜索员工
    remoteMethodStaff(e) {
      getStaffList({ page: 1, limit: 100000, staffName: e }).then(res => {
        this.options.nurse_id = res.list;
      });
    },
    // 选择模板
    tapBtn(routerName, row) {
      if(routerName=='发送'){
        if(this.idList.length>0)
        {
         shortMessageSend({tel: this.idList,tpl_id:row.tpl_id,type:this.tpl_type,template_id:row.template_id}).then(res=>{
             if(res.code==200)
             {
               this.$message({
                 type: 'success',
                 message: res.msg
               });
               this.getList();
               this.dialogVisible=false;
             }else{
                 this.$message.error(res.msg);
             }
         });
        }else{
          shortMessageSends({...this.search,tpl_id:row.tpl_id,type:this.tpl_type,template_id:row.template_id}).then(res=>{
             if(res.code==200)
             {
               this.$message({
                 type: 'success',
                 message: res.msg
               });
               this.getList();
               this.dialogVisible=false;
             }else{
                 this.$message.error(res.msg);
             }
         });
        }
      }
      if(routerName=='选中'){
          this.dialogVisible=true;
        }
	   if(routerName=='短信'){
        this.idList = new Array(row.telephone);
        this.dialogVisible=true;
        this.tpl_type = "shortMessage";
      }
      if(routerName=='公众号'){
         this.idList = new Array(row.telephone);
         this.dialogVisible=true;
         this.tpl_type = "gzh";
       }
    if(routerName=='群发'){
        this.dialogVisible=true;
      }
    },
    // 提示
    // hint(text) {
    //   this.$confirm(`${text},是否确定？`, "提示", {
    //     confirmButtonText: "确定",
    //     cancelButtonText: "取消",
    //     type: "warning"
    //   })
    //     .then(() => {
    //       this.getExport();
    //     })
    //     .catch(() => {
    //       this.$message({
    //         type: "info",
    //         message: "已取消"
    //       });
    //     });
    // },
    // // 导出
    // getExport() {
    //   clientExport({ id: this.idList, type: 1 }).then(res => {
    //     window.open(res.data.url);
    //   });
    // },
    // 改变表格选中状态
    handleSelectionChange(val) {
      this.idList = val.map(i => {
        return i.telephone;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.mainColor {
  color: #2dc0a3;
}
.content {
  height: calc(100% - 56px);
  overflow-y: auto;
}
.bgW {
  background: #fff;
  border-radius: 3px;
  padding: 10px;
  margin-bottom: 20px;
}

.el-form-item {
  margin: 5px 20px 3px 0;
}
.accountCon {
  background: #fff;
  padding: 0px 10px 80px;
  .btnBox {
    display: flex;
    justify-content: space-between;
  }
}
</style>
