import { render, staticRenderFns } from "./shortMessage.vue?vue&type=template&id=5502c420&scoped=true&"
import script from "./shortMessage.vue?vue&type=script&lang=js&"
export * from "./shortMessage.vue?vue&type=script&lang=js&"
import style0 from "./shortMessage.vue?vue&type=style&index=0&id=5502c420&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5502c420",
  null
  
)

export default component.exports